import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Grid,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
  useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const LoginModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState('phone');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const [notifyUpdates, setNotifyUpdates] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMobileNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) setMobileNumber(value);
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && !isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      
      if (value && index < 3) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleSendOtp = async () => {
    if (mobileNumber.length !== 10) return;
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsLoading(false);
    setStep('otp');
  };

  const handleVerifyOtp = async () => {
    if (otp.join('').length !== 4) return;
    setIsLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsLoading(false);
    setStep('success');
    
    setTimeout(() => {
      onClose();
      setStep('phone');
      setMobileNumber('');
      setOtp(['', '', '', '']);
    }, 2000);
  };

  const features = [
    {
      title: "Experience Free Forever",
      desc: "Experience without any subscription fees"
    },
    {
      title: "Best offers",
      desc: "Enjoy unbeatable offers after login"
    },
    {
      title: "100% Secure & Spam Free",
      desc: "Your data is safeguarded and spam-free"
    }
  ];

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'hidden',
          m: 2,
          maxHeight: isMobile ? '90vh' : 'none'
        }
      }}
    >
      <Grid container sx={{ maxHeight: isMobile ? '90vh' : 'none', overflow: 'auto' }}>
        {/* Left Section */}
        <Grid 
          item 
          xs={12} 
          md={6} 
          sx={{ 
            bgcolor: '#066469', 
            position: 'relative', 
            p: isMobile ? 2 : 4,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ 
            textAlign: 'center', 
            mb: isMobile ? 3 : 8,
            mt: isMobile ? 2 : 0
          }}>
            <Box 
              component="img"
              src="/malak-removebg-preview.jpg"
              alt="Logo"
              sx={{ 
                width: isMobile ? 120 : 150,
                height: isMobile ? 51 : 64,
                mb: isMobile ? 2 : 3
              }}
            />
            <Typography 
              variant={isMobile ? "h6" : "h5"} 
              sx={{ color: 'white', fontWeight: 'bold' }}
            >
              Login now to access best offers!
            </Typography>
          </Box>

          <Grid container spacing={isMobile ? 1 : 2}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper 
                  elevation={0}
                  sx={{
                    p: isMobile ? 1.5 : 2,
                    bgcolor: 'transparent',
                    border: '1px solid rgba(255,255,255,0.2)',
                    borderRadius: 2,
                    textAlign: 'center',
                    height: '100%'
                  }}
                >
                  <StarIcon sx={{ 
                    color: '#FFD700', 
                    fontSize: isMobile ? 24 : 32, 
                    mb: 1 
                  }} />
                  <Typography 
                    variant={isMobile ? "body1" : "subtitle1"} 
                    sx={{ color: 'white', fontWeight: 'bold', mb: 0.5 }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(255,255,255,0.8)',
                      fontSize: isMobile ? '0.75rem' : '0.875rem'
                    }}
                  >
                    {feature.desc}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: isMobile ? 2 : 4 }}>
            <Typography variant={isMobile ? "h6" : "h5"} sx={{ fontWeight: 'bold', mb: isMobile ? 2 : 4 }}>
              Login / Signup
            </Typography>

            {step === 'phone' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 2 : 3 }}>
                <TextField
                  fullWidth
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                  variant="outlined"
                  inputProps={{ maxLength: 10 }}
                />

                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={notifyUpdates}
                      onChange={(e) => setNotifyUpdates(e.target.checked)}
                    />
                  }
                  label="Notify me for any updates & offers"
                />

                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleSendOtp}
                  disabled={mobileNumber.length !== 10 || isLoading}
                  sx={{ 
                    bgcolor: '#066469',
                    '&:hover': { bgcolor: '#055459' }
                  }}
                >
                  {isLoading ? 'Sending OTP...' : 'Send OTP'}
                </Button>
              </Box>
            )}

            {step === 'otp' && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobile ? 2 : 3 }}>
                <Alert severity="info" sx={{ mb: isMobile ? 1 : 2 }}>
                  OTP sent to {mobileNumber}
                </Alert>

                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      id={`otp-${index}`}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      variant="outlined"
                      inputProps={{ 
                        maxLength: 1,
                        style: { textAlign: 'center' }
                      }}
                      sx={{ width: isMobile ? '3rem' : '4rem' }}
                    />
                  ))}
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleVerifyOtp}
                  disabled={otp.join('').length !== 4 || isLoading}
                  sx={{ 
                    bgcolor: '#066469',
                    '&:hover': { bgcolor: '#055459' }
                  }}
                >
                  {isLoading ? 'Verifying...' : 'Verify OTP'}
                </Button>
              </Box>
            )}

            {step === 'success' && (
              <Box sx={{ textAlign: 'center', py: isMobile ? 2 : 4 }}>
                <CheckCircleIcon 
                  sx={{ 
                    fontSize: isMobile ? 36 : 48, 
                    color: '#4CAF50',
                    mb: 2
                  }} 
                />
                <Typography variant="h6" sx={{ color: '#4CAF50', fontWeight: 'bold', mb: 1 }}>
                  Login Successful!
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Redirecting you to the dashboard...
                </Typography>
              </Box>
            )}

            <Box sx={{ 
              mt: 2, 
              pt: isMobile ? 4 : 10, 
              textAlign: 'center'
            }}>
              <Typography variant="caption" display="block" sx={{ mb: 1 }}>
                I accept that I have read & understood{' '}
                <Box component="a" href="#" sx={{ color: 'primary.main' }}>
                  Privacy Policy
                </Box>
                {' '}and{' '}
                <Box component="a" href="#" sx={{ color: 'primary.main' }}>
                  T&Cs
                </Box>
              </Typography>
              <Typography variant="body2">
                <Box 
                  component="a" 
                  href="#" 
                  sx={{ 
                    color: 'primary.main',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' }
                  }}
                >
                  Trouble logging in?
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default LoginModal;