import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import {
  ShoppingBag,
  Category,
  Description,
  AssignmentReturn,
  ChevronRight,
  ChevronLeft,
  Close,
} from '@mui/icons-material';

const Sidebar = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubItem, setActiveSubItem] = useState(null);
  
  const baseImageUrl = "https://ik.imagekit.io/srwardmxk/";
  const tealBlue = '#008080';

  const menuItems = [
    { 
      name: 'All Products', 
      icon: <ShoppingBag />,
      color: tealBlue,
      subItems: [
        {
          name: 'All Products',
          image: `${baseImageUrl}Malak_Matic_Laundry_Liquid_Detergent_-_5_Ltr_LruDXXjNi.png`
        },
        {
          name: 'Laundry Care',
          image: `${baseImageUrl}Malak_Matic_Laundry_Liquid_Detergent_-_5_Ltr_LruDXXjNi.png`
        },
        {
          name: 'Kitchen Care',
          image: `${baseImageUrl}Malak_Matic_Laundry_Liquid_Detergent_-_5_Ltr_LruDXXjNi.png`
        },
        {
          name: 'Surface Care',
          image: `${baseImageUrl}Malak_Matic_Laundry_Liquid_Detergent_-_5_Ltr_LruDXXjNi.png`
        },
        {
          name: 'Bathroom Care',
          image: `${baseImageUrl}Malak_Matic_Laundry_Liquid_Detergent_-_5_Ltr_LruDXXjNi.png`
        }
      ]
    },
    { 
      name: 'Categories', 
      icon: <Category />,
      color: tealBlue,
      subItems: [
        { name: 'Home Care' },
        { name: 'Personal Care' }
      ]
    },
    { 
      name: 'Terms of Service', 
      icon: <Description />,
      link: '#',
      color: tealBlue
    },
    { 
      name: 'Refund and Return Policy', 
      icon: <AssignmentReturn />,
      link: '#',
      color: tealBlue
    },
  ];

  const handleCategoryClick = (index) => {
    const item = menuItems[index];
    
    // Handle navigation for Terms and Privacy/Refund links
    if (item.name === 'Terms of Service') {
      navigate('/terms');
      onClose();
      return;
    }
    if (item.name === 'Refund and Return Policy') {
      navigate('/privacy');
      onClose();
      return;
    }
    
    // Original functionality for other items
    setActiveCategory(menuItems[index]);
    setActiveSubItem(null);
  };

  const handleSubItemClick = (category, subItem, index) => {
    setActiveSubItem(index);
    
    // Construct the query parameter based on the category and subItem
    let queryParam = '';
    if (category.name === 'All Products') {
      queryParam = subItem.name === 'All Products' ? '' : `?category=${subItem.name.toLowerCase().replace(' ', '-')}`;
    } else if (category.name === 'Categories') {
      queryParam = `?category=${subItem.name.toLowerCase().replace(' ', '-')}`;
    }
    
    // Navigate to products page with the query parameter
    navigate(`/products${queryParam}`);
    onClose(); // Close the sidebar after navigation
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: activeCategory ? 600 : 300,
          transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          overflow: 'hidden',
          bgcolor: '#FAFAFA'
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        height: '100%',
        position: 'relative',
        overflow: 'hidden'
      }}>
        {/* Main Menu */}
        <Box sx={{ 
          width: 300, 
          borderRight: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff'
        }}>
          <Box sx={{ 
            p: 3, 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
          }}>
            <IconButton 
              onClick={onClose} 
              size="small"
              sx={{ 
                bgcolor: 'rgba(0, 0, 0, 0.04)',
                '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.08)' }
              }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>

          <List sx={{ flexGrow: 1, overflow: 'auto', p: 1.5 }}>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem 
                  disablePadding
                  sx={{ mb: 0.5 }}
                >
                  <ListItemButton
                    onClick={() => handleCategoryClick(index)}
                    selected={activeCategory === menuItems[index]}
                    sx={{
                      borderRadius: 2,
                      '&.Mui-selected': {
                        bgcolor: `${item.color}15`,
                        '&:hover': {
                          bgcolor: `${item.color}25`,
                        }
                      },
                      '&:hover': {
                        bgcolor: `${item.color}10`,
                      }
                    }}
                  >
                    <ListItemText 
                      primary={item.name}
                      primaryTypographyProps={{
                        fontSize: '0.95rem',
                        fontWeight: activeCategory === menuItems[index] ? 600 : 500,
                        color: tealBlue
                      }}
                    />
                    {item.subItems && (
                      <ChevronRight sx={{ ml: 1, color: item.color, opacity: 0.8 }} />
                    )}
                  </ListItemButton>
                </ListItem>
                {index < menuItems.length - 1 && (
                  <Divider sx={{ my: 1.5 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>

        {/* Sub Items */}
        <Box sx={{ 
          position: 'absolute',
          top: 0,
          left: 300,
          width: 300,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fff',
          transform: activeCategory ? 'translateX(0)' : 'translateX(-100%)',
          opacity: activeCategory ? 1 : 0,
          visibility: activeCategory ? 'visible' : 'hidden',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          boxShadow: '-4px 0 8px rgba(0,0,0,0.05)'
        }}>
          <Box sx={{ 
            p: 3, 
            display: 'flex', 
            alignItems: 'center',
            gap: 1.5,
            borderBottom: '1px solid rgba(0, 0, 0, 0.08)'
          }}>
            <IconButton 
              onClick={() => setActiveCategory(null)}
              size="small"
              sx={{ 
                bgcolor: `${activeCategory?.color}15`,
                color: activeCategory?.color,
                '&:hover': { bgcolor: `${activeCategory?.color}25` }
              }}
            >
              <ChevronLeft />
            </IconButton>
          </Box>

          <List sx={{ flexGrow: 1, overflow: 'auto', p: 1.5 }}>
            {activeCategory?.subItems?.map((subItem, index) => (
              <React.Fragment key={index}>
                <ListItem 
                  disablePadding
                  sx={{ mb: 0.5 }}
                >
                  {subItem.isButton ? (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => handleSubItemClick(activeCategory, subItem, index)}
                      sx={{
                        bgcolor: subItem.buttonColor,
                        color: 'white',
                        '&:hover': {
                          bgcolor: subItem.buttonColor
                        }
                      }}
                    >
                      {subItem.name}
                    </Button>
                  ) : (
                    <Box 
                      onClick={() => handleSubItemClick(activeCategory, subItem, index)}
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 2, 
                        width: '100%', 
                        py: 1,
                        px: 2,
                        borderRadius: 2,
                        cursor: 'pointer',
                        bgcolor: activeSubItem === index ? `${tealBlue}15` : 'transparent',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          bgcolor: `${tealBlue}10`,
                        },
                        '&:active': {
                          bgcolor: `${tealBlue}20`,
                        }
                      }}
                    >
                      {subItem.image && (
                        <Box
                          component="img"
                          src={subItem.image}
                          alt={subItem.name}
                          sx={{ 
                            width: 60, 
                            height: 60, 
                            borderRadius: '8px',
                            objectFit: 'cover',
                            bgcolor: '#f5f5f5'
                          }}
                        />
                      )}
                      <Typography
                        sx={{
                          fontSize: '0.95rem',
                          color: tealBlue,
                          fontWeight: activeSubItem === index ? 600 : 500,
                          flexGrow: 1
                        }}
                      >
                        {subItem.name}
                      </Typography>
                    </Box>
                  )}
                </ListItem>
                {index < (activeCategory?.subItems?.length - 1) && (
                  <Divider sx={{ my: 1.5 }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;