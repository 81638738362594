import React, { useState, useEffect } from 'react';
import ImageSlider from './HomePageComponents/sliderImage';
import CustomerAttractionStrip from './HomePageComponents/smallStrip'; 
import BestSellers from './HomePageComponents/BestSeller'; 
import ComboBanner from './HomePageComponents/ComboOfferBanner';
import ComboOffers from './HomePageComponents/ComboOffer'; 
import ProductShowcase from './HomePageComponents/ProductShowcase'; 
import Reviews from './HomePageComponents/Reviews'; 
import Footer from './HomePageComponents/Footer'; 
import SingleBanner from './HomePageComponents/SingleBanner'; 
import '../styles/BestSeller.scss'; 

function HomePage() {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  const images = [
    '/website banner-02.jpg',
    '/website banner-03.jpg',
    '/website banner-04.jpg',
    '/website banner-05.jpg',
  ];

  return (
    <>
      {showContent && (
        <>
         <SingleBanner 
  imageUrl="/website banner-01.jpg" 
  width="1500px"
  height="600px"
/>
          <ImageSlider images={images} />
          <CustomerAttractionStrip />
          <BestSellers />
          <ComboBanner />
          <ComboOffers />
          <ProductShowcase />
          <Reviews />
          <Footer />
        </>
      )}
    </>
  );
}

export default HomePage;
