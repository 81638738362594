// pages/checkout/OrderSuccess.jsx
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/OrderSuccess.scss';

const OrderSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderId = location.state?.orderId;

  if (!orderId) {
    navigate('/');
    return null;
  }

  return (
    <div className="success-container">
      <div className="success-card">
        <div className="success-icon">✓</div>
        <h1>Order Placed Successfully!</h1>
        <p className="order-id">Order ID: {orderId}</p>
        <p className="success-message">
          Thank you for your purchase. We'll send you an email confirmation with order details
          and tracking information once your order ships.
        </p>
        <div className="action-buttons">
          <button onClick={() => navigate('/orders')} className="primary-button">
            View Orders
          </button>
          <button onClick={() => navigate('/')} className="secondary-button">
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;