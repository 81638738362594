import React from 'react';
import '../../styles/FilterComponent.scss';

const FilterComponent = ({
  filterCategory,
  setFilterCategory,
  volumeRange,
  setVolumeRange,
  fragrance,
  setFragrance,
  qtyRange,
  setQtyRange,
  priceRange,
  setPriceRange,
  availability,
  setAvailability,
  isMobileFilterOpen,
  onClose
}) => {
  const handleClearFilters = () => {
    setFilterCategory('All');
    setVolumeRange(10);
    setFragrance('All');
    setQtyRange(100);
    setPriceRange(2000);
    setAvailability('All');
  };

  return (
    <>
      <div className={`filter-wrapper ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
        <div className="filter-component">
          <div className="filter-header">
            <h2 className="filter-title">
              <span className="filter-icon"></span>
              Filters
            </h2>
            <div className="filter-actions">
              <button className="clear-all" onClick={handleClearFilters}>
                Clear All
              </button>
              <button className="close-button" onClick={onClose} aria-label="Close filters">
                ×
              </button>
            </div>
          </div>

          <div className="filter-content">
            <div className="filter-group">
              <label htmlFor="category">Category</label>
              <select 
                id="category"
                value={filterCategory}
                onChange={(e) => setFilterCategory(e.target.value)}
              >
                <option value="All">All Categories</option>
                <option value="Toilet Cleaners">Toilet Cleaners</option>
                <option value="Laundry Detergent">Laundry Detergent</option>
                <option value="Dishwashing Gel">Dishwashing Gel</option>
                <option value="Disinfectant Cleaner">Disinfectant Cleaner</option>
                <option value="Bathroom Cleaners">Bathroom Cleaners</option>
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="volume">Volume: {volumeRange} ml</label>
              <input 
                id="volume"
                type="range" 
                min="0" 
                max="1000" 
                value={volumeRange}
                onChange={(e) => setVolumeRange(Number(e.target.value))}
              />
              <div className="range-labels">
                <span>0 ml</span>
                <span>1000 ml</span>
              </div>
            </div>

            <div className="filter-group">
              <label htmlFor="fragrance">Fragrance</label>
              <select 
                id="fragrance"
                value={fragrance}
                onChange={(e) => setFragrance(e.target.value)}
              >
                <option value="All">All Fragrances</option>
                <option value="Floral">Floral</option>
                <option value="Woody">Woody</option>
                <option value="Citrus">Citrus</option>
              </select>
            </div>

            <div className="filter-group">
              <label htmlFor="quantity">Quantity: {qtyRange}</label>
              <input 
                id="quantity"
                type="range" 
                min="0" 
                max="100" 
                value={qtyRange}
                onChange={(e) => setQtyRange(Number(e.target.value))}
              />
              <div className="range-labels">
                <span>0</span>
                <span>100</span>
              </div>
            </div>

            <div className="filter-group">
              <label htmlFor="price">Price: ₹{priceRange}</label>
              <input 
                id="price"
                type="range" 
                min="0" 
                max="1000" 
                value={priceRange}
                onChange={(e) => setPriceRange(Number(e.target.value))}
              />
              <div className="range-labels">
                <span>₹0</span>
                <span>₹1000</span>
              </div>
            </div>

            <div className="filter-group">
              <label htmlFor="availability">Availability</label>
              <select 
                id="availability"
                value={availability}
                onChange={(e) => setAvailability(e.target.value)}
              >
                <option value="All">All</option>
                <option value="In Stock">In Stock</option>
                <option value="Out of Stock">Out of Stock</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      {isMobileFilterOpen && <div className="filter-overlay" onClick={onClose} />}
    </>
  );
};

export default FilterComponent;