import React, { createContext, useContext, useState } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes, 
  Navigate, 
  useNavigate, 
  useLocation 
} from 'react-router-dom';
import HomePage from '../src/components/HomePage';
import ProductPage from './components/ProductPage';
import ProductDetailsPage from './components/ProductPageComponents/ProductDetails';
import CartPage from './components/CartPages/CartPage';
import AnimatedBanner from '../src/components/HomePageComponents/AnimatedBanner';
import Navbar from './components/HomePageComponents/Navbar';
import OrdersList from './components/order/orders';
import OrderTracking from './components/order/orderTracking';
import AddressCheckout from './components/order/AddressCheckout';
import PaymentCheckout from './components/order/PaymentCheckout';
import OrderSuccess from './components/order/OrderSuccess';
import LoginModal from './components/Signin_up/Signin';
import TermsAndConditions from './components/TermsCondition/Terms&Condition'; 
import Privacy from './components/TermsCondition/Privacy'; 

// Create Authentication Context
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

// Auth Provider Component
const AuthProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingLocation, setPendingLocation] = useState(null);

  const isAuthenticated = !!localStorage.getItem('user');

  const openAuthModal = (targetLocation) => {
    setIsModalOpen(true);
    setPendingLocation(targetLocation);
  };

  const closeAuthModal = () => {
    setIsModalOpen(false);
    setPendingLocation(null);
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      openAuthModal, 
      closeAuthModal, 
      pendingLocation 
    }}>
      {children}
      <LoginModal 
        isOpen={isModalOpen} 
        onClose={closeAuthModal}
      />
    </AuthContext.Provider>
  );
};

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, openAuthModal } = useAuth();
  
  React.useEffect(() => {
    if (!isAuthenticated) {
      openAuthModal(location.pathname);
    }
  }, [isAuthenticated, location, openAuthModal]);

  return isAuthenticated ? children : null;
};

// NotFound Component
const NotFound = () => {
  const navigate = useNavigate();
  
  return (
    <div className="not-found">
      <h1>404 - Page Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      <button onClick={() => navigate('/')}>Go Home</button>
    </div>
  );
};

function App() {
  return (
    <React.StrictMode>
      <Router>
        <AuthProvider>
          <div className="App">
            <AnimatedBanner />
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/products" element={<ProductPage />} />
              <Route path="/product/:id" element={<ProductDetailsPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/terms" element={<TermsAndConditions />} />  
              <Route path="/privacy" element={<Privacy />} />  
              
              <Route path="/orders">
                <Route 
                  index
                  element={
                    // <ProtectedRoute>
                      <OrdersList />
                    // </ProtectedRoute>
                  } 
                />
                <Route 
                  path="tracking/:orderId" 
                  element={
                    // <ProtectedRoute>
                      <OrderTracking />
                    // </ProtectedRoute>
                  } 
                />
              </Route>
              
              <Route path="/checkout">
                <Route 
                  path="address" 
                  element={
                    // <ProtectedRoute>
                      <AddressCheckout />
                    // </ProtectedRoute>
                  } 
                />
                <Route 
                  path="payment" 
                  element={
                    // <ProtectedRoute>
                      <PaymentCheckout />
                    // </ProtectedRoute>
                  } 
                />
                <Route 
                  path="success" 
                  element={
                    // <ProtectedRoute>
                      <OrderSuccess />
                    // </ProtectedRoute>
                  } 
                />
                <Route 
                  index 
                  element={<Navigate to="/checkout/address" replace />} 
                />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AuthProvider>
      </Router>
    </React.StrictMode>
  );
}

export default App;