// pages/checkout/PaymentCheckout.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/PaymentCheckout.scss';

const PaymentCheckout = () => {
  const navigate = useNavigate();
  const [checkoutData, setCheckoutData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedCheckoutData = localStorage.getItem('checkoutData');
    if (!savedCheckoutData) {
      navigate('/cart');
      return;
    }
    setCheckoutData(JSON.parse(savedCheckoutData));
  }, [navigate]);

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Simulate payment processing
    setTimeout(() => {
      const orderId = `ORD${Date.now()}`;
      const orderData = {
        ...checkoutData,
        orderId,
        paymentMethod,
        orderStatus: 'confirmed',
        orderDate: new Date().toISOString()
      };

      // Save order to localStorage
      const savedOrders = JSON.parse(localStorage.getItem('orders') || '[]');
      localStorage.setItem('orders', JSON.stringify([...savedOrders, orderData]));

      // Clear cart and checkout data
      localStorage.removeItem('cart');
      localStorage.removeItem('checkoutData');

      // Navigate to success page
      navigate('/checkout/success', { state: { orderId } });
    }, 1500);
  };

  if (!checkoutData) return null;

  return (
    <div className="payment-container">
      <div className="payment-grid">
        <div className="payment-section">
          <div className="payment-header">
            <h1>Payment Method</h1>
            <div className="checkout-steps">
              <span className="step completed">Address</span>
              <span className="step active">Payment</span>
              <span className="step">Confirmation</span>
            </div>
          </div>

          <form onSubmit={handlePaymentSubmit}>
            <div className="payment-methods">
              <label className={`payment-method ${paymentMethod === 'card' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="card"
                  checked={paymentMethod === 'card'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <div className="method-content">
                  <div className="method-icon">💳</div>
                  <div className="method-details">
                    <h3>Credit/Debit Card</h3>
                    <p>Pay securely using your card</p>
                  </div>
                </div>
              </label>

              <label className={`payment-method ${paymentMethod === 'upi' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="upi"
                  checked={paymentMethod === 'upi'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <div className="method-content">
                  <div className="method-icon">📱</div>
                  <div className="method-details">
                    <h3>UPI</h3>
                    <p>Pay using UPI apps</p>
                  </div>
                </div>
              </label>

              <label className={`payment-method ${paymentMethod === 'cod' ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="cod"
                  checked={paymentMethod === 'cod'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
                <div className="method-content">
                  <div className="method-icon">💰</div>
                  <div className="method-details">
                    <h3>Cash on Delivery</h3>
                    <p>Pay when you receive your order</p>
                  </div>
                </div>
              </label>
            </div>

            {paymentMethod === 'card' && (
              <div className="card-details">
                <div className="form-group">
                  <label>Card Number</label>
                  <input type="text" placeholder="1234 5678 9012 3456" maxLength="16" required />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label>Expiry Date</label>
                    <input type="text" placeholder="MM/YY" maxLength="5" required />
                  </div>
                  <div className="form-group">
                    <label>CVV</label>
                    <input type="text" placeholder="123" maxLength="3" required />
                  </div>
                </div>
                <div className="form-group">
                  <label>Name on Card</label>
                  <input type="text" placeholder="John Doe" required />
                </div>
              </div>
            )}

            {paymentMethod === 'upi' && (
              <div className="upi-details">
                <div className="form-group">
                  <label>UPI ID</label>
                  <input type="text" placeholder="example@upi" required />
                </div>
              </div>
            )}

            <button 
              type="submit" 
              className="payment-button"
              disabled={loading}
            >
              {loading ? 'Processing...' : `Pay ₹${checkoutData.pricing.total.toLocaleString('en-IN')}`}
            </button>
          </form>
        </div>

        <div className="order-summary">
          <h2>Order Summary</h2>
          <div className="delivery-address">
            <h3>Delivery Address</h3>
            <div className="address-details">
              <p><strong>{checkoutData.shippingAddress.fullName}</strong></p>
              <p>{checkoutData.shippingAddress.address1}</p>
              {checkoutData.shippingAddress.address2 && (
                <p>{checkoutData.shippingAddress.address2}</p>
              )}
              <p>
                {checkoutData.shippingAddress.city}, {checkoutData.shippingAddress.state}
                {" "}{checkoutData.shippingAddress.pincode}
              </p>
              <p>Phone: {checkoutData.shippingAddress.phone}</p>
            </div>
          </div>

          <div className="summary-items">
            {checkoutData.cart.map(item => (
              <div key={item._id} className="summary-item">
                <img src={item.imageUrl[0]} alt={item.name} />
                <div className="item-details">
                  <h4>{item.name}</h4>
                  <p>Qty: {item.quantity}</p>
                  <p className="price">₹{(item.price * item.quantity).toLocaleString('en-IN')}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="summary-totals">
            <div className="summary-row">
              <span>Subtotal</span>
              <span>₹{checkoutData.pricing.subtotal.toLocaleString('en-IN')}</span>
            </div>
            {checkoutData.pricing.discount > 0 && (
              <div className="summary-row discount">
                <span>Discount</span>
                <span>-₹{checkoutData.pricing.discount.toLocaleString('en-IN')}</span>
              </div>
            )}
            <div className="summary-row total">
              <span>Total</span>
              <span>₹{checkoutData.pricing.total.toLocaleString('en-IN')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCheckout;