import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaStar, FaTruck } from 'react-icons/fa';
import Footer from '../HomePageComponents/Footer';
import BestSellers from '../HomePageComponents/BestSeller';
import axiosInstance from '../../helpers/axiosconfig';
import '../../styles/ProductDetails.scss';
import Navbar from '../HomePageComponents/Navbar';

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <FaStar key={star} className={`star ${star <= rating ? 'filled' : ''}`} />
      ))}
      <span className="rating-value">({rating.toFixed(1)})</span>
    </div>
  );
};

const DeliveryEstimation = ({ product }) => {
  const [pincode, setPincode] = useState('');
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [error, setError] = useState(null);

  const checkDelivery = async () => {
    setIsChecking(true);
    setError(null);
    
    try {
      // First check if pincode is serviceable
      const pincodeResponse = await axiosInstance.get(`shipping/check-pincode/${pincode}`);
      
      if (!pincodeResponse.data.success) {
        setDeliveryInfo({
          available: false,
          message: "Sorry, delivery is not available in your area."
        });
        return;
      }

      // Set default dimensions and weight if not provided
      const defaultDimensions = {
        length: 10,  // default length in cm
        breadth: 10, // default breadth in cm
        height: 10,  // default height in cm
        weight: 0.5  // default weight in kg
      };

      // Ensure product price is a positive number
      const parcelValue = product?.price || product?.discountedPrice || 1000; // Fallback to 1000 if no price available
      
      if (!parcelValue || parcelValue <= 0) {
        throw new Error('Invalid product price');
      }

      // Get shipping estimate with fallback values
      const estimateResponse = await axiosInstance.post('shipping/estimate', {
        length: product?.dimensions?.length || defaultDimensions.length,
        breadth: product?.dimensions?.breadth || defaultDimensions.breadth,
        height: product?.dimensions?.height || defaultDimensions.height,
        weight: product?.weight || defaultDimensions.weight,
        destination_pincode: pincode,
        origin_pincode: process.env.REACT_APP_WAREHOUSE_PINCODE || '361006',
        destination_country_code: 'IN',
        origin_country_code: 'IN',
        shipment_mode: 'E', // Express delivery
        shipment_type: 'P', // Prepaid
        shipment_value: parcelValue
      });

      if (estimateResponse.data.success) {
        const deliveryDate = new Date();
        deliveryDate.setDate(deliveryDate.getDate() + 
          Math.ceil(estimateResponse.data.estimated_delivery_days || 4));

        setDeliveryInfo({
          available: true,
          estimatedDays: estimateResponse.data.estimated_delivery_days || '3-5',
          expectedDate: deliveryDate.toLocaleDateString('en-IN', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          }),
          shippingCost: estimateResponse.data.total_charge || 0
        });
      } else {
        throw new Error(estimateResponse.data.error || 'Failed to get delivery estimate');
      }
    } catch (err) {
      console.error('Delivery check error:', err);
      setError(err.message === 'Invalid product price' 
        ? 'Unable to calculate shipping cost due to invalid product price.' 
        : 'Unable to check delivery availability. Please try again.');
      setDeliveryInfo(null);
    } finally {
      setIsChecking(false);
    }
  };

  // Calculate the actual price considering discount if present
  const getProductValue = () => {
    if (!product) return 1000; // Default fallback value
    
    if (product.discount && product.price) {
      return product.price * (1 - (product.discount / 100));
    }
    
    return product.price || 1000;
  };

  return (
    <div className="delivery-estimation">
      <div className="delivery-header">
        <div className="icon-container">
          <FaTruck className="truck-icon" />
        </div>
        <div className="header-text">
          <h3>Delivery Options</h3>
          <p>Check delivery availability in your area</p>
        </div>
      </div>

      <div className="delivery-content">
        <div className="pincode-input-container">
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value.replace(/\D/g, '').slice(0, 6))}
            placeholder="Enter your pincode"
            maxLength="6"
            className={error ? 'error' : ''}
          />
          <button
            onClick={checkDelivery}
            disabled={pincode.length !== 6 || isChecking}
            className={`check-button ${pincode.length === 6 && !isChecking ? 'active' : ''}`}
          >
            {isChecking ? (
              <div className="loading-state">
                <div className="spinner"></div>
                <span>Checking</span>
              </div>
            ) : (
              'Check'
            )}
          </button>
        </div>

        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        {deliveryInfo && (
          <div className={`delivery-info ${deliveryInfo.available ? 'available' : 'unavailable'}`}>
            {deliveryInfo.available ? (
              <div className="info-content">
                <div className="status-indicator">
                  <span className="status-dot"></span>
                  <span className="status-text">Delivery Available</span>
                </div>
                <div className="delivery-details">
                  <p className="estimation">
                    Estimated delivery in <span>{deliveryInfo.estimatedDays} business days</span>
                  </p>
                  <p className="expected-date">
                    Expected by {deliveryInfo.expectedDate}
                  </p>
                  {deliveryInfo.shippingCost > 0 && (
                    <p className="shipping-cost">
                      Shipping Cost: ₹{deliveryInfo.shippingCost.toFixed(2)}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div className="info-content">
                <div className="status-indicator">
                  <span className="status-dot"></span>
                  <span className="status-text">{deliveryInfo.message}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};


const ProductDetailsPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);

  const [descriptionImages, setDescriptionImages] = useState([
    { 
      id: 1, 
      image: "/Small-3.jpg",
      label: "Description 1",
      description: "Detailed explanation of feature 1"
    },
    { 
      id: 2, 
      image: "/Small-3.jpg",
      label: "Description 2",
      description: "Detailed explanation of feature 2"
    },
    { 
      id: 3, 
      image: "/Small-3.jpg",
      label: "Description 3",
      description: "Detailed explanation of feature 3"
    },
    { 
      id: 4, 
      image: "/Small-3.jpg",
      label: "Description 4",
      description: "Detailed explanation of feature 4"
    }
  ]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`products/${id}`);
        setProduct(response.data);
        if (response.data.colors && response.data.colors.length > 0) {
          setSelectedColor(response.data.colors[0]);
        }
        if (response.data.sizes && response.data.sizes.length > 0) {
          setSelectedSize(response.data.sizes[0]);
        }
      } catch (err) {
        setError('Failed to fetch product details. Please try again later.');
        console.error('Error fetching product:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) return <div className="product-details-page loading">Loading...</div>;
  if (error) return <div className="product-details-page error">{error}</div>;
  if (!product) return <div className="product-details-page not-found">Product not found</div>;

  const handleAddToCart = () => {
    const newItem = {
      ...product,
      quantity,
      selectedColor,
      selectedSize,
    };

    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingItemIndex = storedCart.findIndex(item => 
      item._id === newItem._id && 
      item.selectedColor === newItem.selectedColor && 
      item.selectedSize === newItem.selectedSize
    );

    if (existingItemIndex !== -1) {
      storedCart[existingItemIndex].quantity += quantity;
    } else {
      storedCart.push(newItem);
    }

    localStorage.setItem('cart', JSON.stringify(storedCart));

    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: storedCart }
    }));

    window.dispatchEvent(new CustomEvent('openCartSidebar'));
  };

  const handleBuyNow = () => {
    handleAddToCart();
    window.location.href = '/checkout/address';
  };

  const discountedPrice = product.price * (1 - (product.discount || 0) / 100);

  return (
    <div className="product-details-page">
      <main className="main-content">
        <div className="product-details">
          <div className="product-images">
            <div className="main-image-container">
              {product.imageUrl && product.imageUrl.length > 0 && (
                <img src={product.imageUrl[selectedImage]} alt={product.name} />
              )}
            </div>
            <div className="thumbnail-container">
              {product.imageUrl && product.imageUrl.map((image, index) => (
                <div
                  key={index}
                  className={`thumbnail ${selectedImage === index ? 'selected' : ''}`}
                  onClick={() => setSelectedImage(index)}
                >
                  <img src={image} alt={`${product.name} - Image ${index + 1}`} />
                </div>
              ))}
            </div>

            
          </div>
          
          <div className="product-info">
            <h2>{product.name}</h2>
            <StarRating rating={product.rating || 0} />
            <div className="price-container">
              <p className="mrp-label">MRP:</p>
              <p className="sale-price">₹{discountedPrice.toFixed(2)}</p>
              {product.discount > 0 && (
                <>
                  <p className="original-price">₹{product.price.toFixed(2)}</p>
                  <p className="savings">({product.discount}% off)</p>
                </>
              )}
            </div>
            
            <DeliveryEstimation />
            
            {product.colors && product.colors.length > 0 && (
              <div className="options">
                <div className="option">
                  <h3>Color:</h3>
                  <div className="option-buttons">
                    {product.colors.map((color) => (
                      <button
                        key={color}
                        className={`option-button color-button ${selectedColor === color ? 'selected' : ''}`}
                        style={{ backgroundColor: color.toLowerCase().replace(' ', '') }}
                        onClick={() => setSelectedColor(color)}
                        title={color}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            
            {product.sizes && product.sizes.length > 0 && (
              <div className="options">
                <div className="option">
                  <h3>Size:</h3>
                  <div className="option-buttons">
                    {product.sizes.map((size) => (
                      <button
                        key={size}
                        className={`option-button ${selectedSize === size ? 'selected' : ''}`}
                        onClick={() => setSelectedSize(size)}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            
            <div className="quantity">
              <h3>Quantity:</h3>
              <div className="quantity-selector">
                <button onClick={() => setQuantity(Math.max(1, quantity - 1))}>-</button>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                />
                <button onClick={() => setQuantity(quantity + 1)}>+</button>
              </div>
            </div>
            
            {product.stock && product.stock < 10 && (
              <p className="stock-warning">Only {product.stock} units left in stock!</p>
            )}
            
            <div className="action-buttons">
              <button className="add-to-cart" onClick={handleAddToCart}>Add to Cart</button>
              <button className="buy-now" onClick={handleBuyNow}>Buy Now</button>
            </div>

            <div className="product-about">
              <h3>About this item</h3>
              <ul>
                {product.description && product.description.split('.').filter(Boolean).map((point, index) => (
                  <li key={index}>{point.trim()}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="description-section">
              <h3 className="description-title">Description</h3>
              <div className="description-images">
                {descriptionImages.map((desc) => (
                  <div 
                    key={desc.id}
                    className="description-image-wrapper"
                  >
                    <img src={desc.image} alt="Product description" />
                  </div>
                ))}
              </div>
            </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;