import React from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/orderTracking.scss';

const OrderTracking = () => {
  const { orderId } = useParams();

  // Simulated data fetching based on orderId
  const getOrderData = (id) => {
    // In a real app, this would be an API call
    return {
      orderId: id,
      orderDate: "2024-10-25T10:30:00",
      estimatedDelivery: "2024-10-29",
      currentStatus: "in_transit",
      items: [
        {
          name: "Blue Comfort Fit T-Shirt",
          quantity: 2,
          price: 1499,
          image: "/api/placeholder/80/80"
        },
        {
          name: "Classic Black Jeans",
          quantity: 1,
          price: 2999,
          image: "/api/placeholder/80/80"
        }
      ],
      trackingSteps: [
        {
          status: "ordered",
          title: "Order Placed",
          description: "Your order has been confirmed",
          timestamp: "2024-10-25T10:30:00",
          completed: true
        },
        {
          status: "processed",
          title: "Order Processed",
          description: "Your order has been processed and packed",
          timestamp: "2024-10-26T14:20:00",
          completed: true
        },
        {
          status: "in_transit",
          title: "In Transit",
          description: "Your order is on the way",
          timestamp: "2024-10-27T09:15:00",
          completed: true,
          currentLocation: "Mumbai Distribution Center"
        },
        {
          status: "out_for_delivery",
          title: "Out for Delivery",
          description: "Your order will be delivered today",
          timestamp: null,
          completed: false
        },
        {
          status: "delivered",
          title: "Delivered",
          description: "Your order has been delivered",
          timestamp: null,
          completed: false
        }
      ],
      shippingAddress: {
        name: "John Doe",
        street: "123 Main Street",
        city: "Mumbai",
        state: "Maharashtra",
        pincode: "400001",
        phone: "+91 98765 43210"
      }
    };
  };

  const orderData = getOrderData(orderId);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    }).format(price);
  };

  return (
    <div className="tracking-page">
      <div className="tracking-container">
        <header className="tracking-header">
          <div className="order-info">
            <h1>Track Your Order</h1>
            <div className="order-meta">
              <span className="order-id">Order ID: {orderData.orderId}</span>
              <span className="order-date">
                Ordered on {formatDate(orderData.orderDate)}
              </span>
            </div>
          </div>
          <div className="estimated-delivery">
            <div className="delivery-icon">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z" />
              </svg>
            </div>
            <div className="delivery-info">
              <span className="label">Estimated Delivery</span>
              <span className="date">{new Date(orderData.estimatedDelivery).toLocaleDateString('en-IN', { 
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}</span>
            </div>
          </div>
        </header>

        <div className="tracking-content">
          <div className="tracking-timeline">
            <div className="timeline-header">
              <h2>Shipment Progress</h2>
            </div>
            <div className="timeline-steps">
              {orderData.trackingSteps.map((step, index) => (
                <div 
                  key={step.status} 
                  className={`timeline-step ${step.completed ? 'completed' : ''} ${
                    orderData.currentStatus === step.status ? 'current' : ''
                  }`}
                >
                  <div className="step-marker">
                    <div className="marker-icon">
                      {step.completed ? (
                        <svg className="check-icon" viewBox="0 0 24 24">
                          <path d="M20 6L9 17l-5-5" />
                        </svg>
                      ) : (
                        <div className="marker-dot" />
                      )}
                    </div>
                    {index !== orderData.trackingSteps.length - 1 && (
                      <div className="marker-line" />
                    )}
                  </div>
                  <div className="step-content">
                    <h3>{step.title}</h3>
                    <p>{step.description}</p>
                    {step.timestamp && (
                      <span className="timestamp">{formatDate(step.timestamp)}</span>
                    )}
                    {step.currentLocation && (
                      <div className="current-location">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" />
                          <circle cx="12" cy="9" r="2.5" />
                        </svg>
                        <span>{step.currentLocation}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="tracking-details">
            <div className="order-items">
              <h2>Order Details</h2>
              <div className="items-list">
                {orderData.items.map((item, index) => (
                  <div key={index} className="item-card">
                    <img src={item.image} alt={item.name} className="item-image" />
                    <div className="item-info">
                      <h3>{item.name}</h3>
                      <div className="item-meta">
                      <span className="quantity">Qty: {item.quantity}</span>
                        <span className="price">{formatPrice(item.price)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="shipping-info">
              <h2>Delivery Address</h2>
              <div className="address-card">
                <div className="address-icon">
                  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" />
                    <circle cx="12" cy="9" r="2.5" />
                  </svg>
                </div>
                <div className="address-details">
                  <h3>{orderData.shippingAddress.name}</h3>
                  <p>{orderData.shippingAddress.street}</p>
                  <p>{orderData.shippingAddress.city}, {orderData.shippingAddress.state} {orderData.shippingAddress.pincode}</p>
                  <p className="phone">{orderData.shippingAddress.phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTracking;