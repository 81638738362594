// ProductCard.jsx
import React from 'react';
import { BsCartPlus, BsLightningCharge, BsCheckCircleFill, BsExclamationCircle } from 'react-icons/bs';
import axios from '../../helpers/axiosconfig';
import { useNavigate } from 'react-router-dom';
import '../../styles/ProductCard.scss';

const ProductCard = ({ product, onAddToCart, onProductClick }) => {
  const navigate = useNavigate();
  const {
    _id,
    name,
    description,
    imageUrl,
    price,
    stock,
    discount,
    volume,
    quantity,
    fragrance
  } = product;

  const isInStock = stock > 0;
  const formattedPrice = price.toLocaleString('en-IN');
  const discountedPrice = price - (price * discount / 100);
  const formattedDiscountedPrice = discountedPrice.toLocaleString('en-IN');
  
  const renderStockBadge = () => {
    if (stock > 10) {
      return (
        <div className="badge in-stock">
          <BsCheckCircleFill className="badge-icon" />
          <span>In Stock</span>
        </div>
      );
    } else if (stock > 0) {
      return (
        <div className="badge low-stock">
          <BsExclamationCircle className="badge-icon" />
          <span>Only {stock} left</span>
        </div>
      );
    }
    return (
      <div className="badge out-stock">
        <BsExclamationCircle className="badge-icon" />
        <span>Out of Stock</span>
      </div>
    );
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePaymentSuccess = async (response) => {
    try {
      await axios.post('/order/verify', {
        razorpayOrderId: response.razorpay_order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpaySignature: response.razorpay_signature
      });
      navigate('/order-success');
    } catch (error) {
      console.error('Payment verification failed:', error);
      alert('Payment verification failed. Please contact support.');
    }
  };

  const handleBuyNow = async () => {
    try {
      if (!isInStock) return;

      const orderData = {
        amount: discount > 0 ? discountedPrice : price,
        currency: 'INR',
        orderId: Date.now().toString(),
        items: [{
          ...product,
          quantity: 1
        }]
      };
      
      const response = await axios.post('/order/create', orderData);
      const { razorpayOrderId } = response.data;

      const res = await initializeRazorpay();
      if (!res) {
        alert('Razorpay SDK failed to load');
        return;
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: (discount > 0 ? discountedPrice : price) * 100,
        currency: 'INR',
        name: 'Malak',
        description: `Purchase of ${name}`,
        order_id: razorpayOrderId,
        handler: handlePaymentSuccess,
        prefill: {
          name: '',
          email: '',
          contact: ''
        },
        theme: {
          color: '#3399cc'
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Checkout failed:', error);
      alert('Failed to initialize checkout. Please try again.');
    }
  };

  const handleAddToCart = () => {
    if (!isInStock) return;
    onAddToCart(product);
  };

  const handleProductClick = () => {
    if (onProductClick) {
      onProductClick(_id);
    }
  };

  return (
    <div className="product-card">
      <div className="card-header">
        {renderStockBadge()}
        {discount > 0 && (
          <div className="discount-badge">
            <span>{discount}% OFF</span>
          </div>
        )}
      </div>

      <div className="image-container" onClick={handleProductClick}>
        {imageUrl && imageUrl.length > 0 ? (
          <img
            src={imageUrl[0]}
            alt={name}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
              e.target.nextElementSibling.style.display = 'flex';
            }}
          />
        ) : (
          <div className="image-placeholder">
            <div className="placeholder-icon">📷</div>
            <p>Image not available</p>
          </div>
        )}
      </div>

      <div className="product-content">
        <h3 className="product-title" onClick={handleProductClick}>
          {name}
        </h3>
        
        <p className="product-description">{description}</p>

        <div className="product-details">
          <div className="specs-row">
            <div className="spec-item">
              <span className="spec-label">Volume</span>
              <span className="spec-value">{volume} Ltr</span>
            </div>
            <div className="spec-item">
              <span className="spec-label">Quantity</span>
              <span className="spec-value">{quantity}</span>
            </div>
          </div>
          {fragrance !== "Na" && (
            <div className="spec-item fragrance">
              <span className="spec-label">Fragrance</span>
              <span className="spec-value">{fragrance}</span>
            </div>
          )}
        </div>

        <div className="price-block">
          <div className="price-info">
            <div className="current-price">
              <span className="currency">₹</span>
              <span className="amount">
                {discount > 0 ? formattedDiscountedPrice : formattedPrice}
              </span>
            </div>
            {discount > 0 && (
              <div className="original-price">
                <span className="currency">₹</span>
                <span className="amount">{formattedPrice}</span>
              </div>
            )}
          </div>
          {isInStock && (
            <div className="shipping-info">
              <span>Free Delivery</span>
            </div>
          )}
        </div>

        <div className="action-buttons">
          <button 
            className="cart-button secondary"
            onClick={handleAddToCart}
            disabled={!isInStock}
            aria-label="Add to cart"
          >
            <BsCartPlus className="button-icon" />
            <span>Add to Cart</span>
          </button>
          <button 
            className="buy-button primary"
            onClick={handleBuyNow}
            disabled={!isInStock}
            aria-label="Buy now"
          >
            <BsLightningCharge className="button-icon" />
            <span>Buy Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;