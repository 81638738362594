import React from 'react';
import '../../styles/SingleBanner.scss';

const SingleBanner = ({ 
  imageUrl, 
  altText = 'Banner image',
  height = '100px' // Default height
}) => {
  return (
    <div className="single-banner-container">
      <div className="banner-wrapper">
        <div 
          className="banner-content"
          style={{ height }}
        >
          <img
            src={imageUrl}
            alt={altText}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleBanner;