// pages/checkout/AddressCheckout.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddressForm from './addressForm'; 
import '../../styles/AddressCheckout.scss';

const AddressCheckout = () => {
  const navigate = useNavigate();
  const [checkoutData, setCheckoutData] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addresses, setAddresses] = useState(() => {
    const savedAddresses = localStorage.getItem('savedAddresses');
    return savedAddresses ? JSON.parse(savedAddresses) : [];
  });

  useEffect(() => {
    const savedCheckoutData = localStorage.getItem('checkoutData');
    if (!savedCheckoutData) {
      navigate('/cart');
      return;
    }
    setCheckoutData(JSON.parse(savedCheckoutData));

    // Select default address if exists
    const defaultAddress = addresses.find(addr => addr.isDefault);
    if (defaultAddress) {
      setSelectedAddress(defaultAddress);
    }
  }, [navigate]);

  const handleAddressSubmit = (addressData) => {
    const newAddress = {
      id: Date.now(),
      ...addressData
    };
    
    const updatedAddresses = [...addresses, newAddress];
    setAddresses(updatedAddresses);
    setSelectedAddress(newAddress);
    localStorage.setItem('savedAddresses', JSON.stringify(updatedAddresses));
    setShowAddressForm(false);
  };

  const proceedToPayment = () => {
    if (!selectedAddress) return;

    const updatedCheckoutData = {
      ...checkoutData,
      shippingAddress: selectedAddress
    };
    localStorage.setItem('checkoutData', JSON.stringify(updatedCheckoutData));
    navigate('/checkout/payment');
  };

  if (!checkoutData) return null;

  return (
    <div className="checkout-container">
      <div className="checkout-grid">
        <div className="address-section">
          <div className="address-header">
            <h1>Select Delivery Address</h1>
            <div className="checkout-steps">
              <span className="step active">Address</span>
              <span className="step">Payment</span>
              <span className="step">Confirmation</span>
            </div>
          </div>

          <div className="address-list">
            {addresses.map(address => (
              <div 
                key={address.id}
                className={`address-card ${selectedAddress?.id === address.id ? 'selected' : ''}`}
                onClick={() => setSelectedAddress(address)}
              >
                <div className="address-type">{address.type}</div>
                <div className="address-content">
                  <h3>{address.fullName}</h3>
                  <p>{address.address1}</p>
                  <p>{address.address2}</p>
                  <p>{address.city}, {address.state} {address.pincode}</p>
                  <p>Phone: {address.phone}</p>
                </div>
                {address.isDefault && <div className="default-badge">Default</div>}
              </div>
            ))}

            <div className="add-address-card" onClick={() => setShowAddressForm(true)}>
              <div className="plus-icon">+</div>
              <h3>Add New Address</h3>
            </div>
          </div>

          <div className="address-actions">
            <button 
              className="proceed-button"
              disabled={!selectedAddress}
              onClick={proceedToPayment}
            >
              Deliver to this Address
            </button>
          </div>
        </div>

        <div className="order-summary">
          <h2>Order Summary</h2>
          <div className="summary-items">
            {checkoutData.cart.map(item => (
              <div key={item._id} className="summary-item">
                <img src={item.imageUrl[0]} alt={item.name} />
                <div className="item-details">
                  <h4>{item.name}</h4>
                  <p>Qty: {item.quantity}</p>
                  <p className="price">₹{(item.price * item.quantity).toLocaleString('en-IN')}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="summary-totals">
            <div className="summary-row">
              <span>Subtotal</span>
              <span>₹{checkoutData.pricing.subtotal.toLocaleString('en-IN')}</span>
            </div>
            {checkoutData.pricing.discount > 0 && (
              <div className="summary-row discount">
                <span>Discount</span>
                <span>-₹{checkoutData.pricing.discount.toLocaleString('en-IN')}</span>
              </div>
            )}
            <div className="summary-row total">
              <span>Total</span>
              <span>₹{checkoutData.pricing.total.toLocaleString('en-IN')}</span>
            </div>
          </div>
        </div>
      </div>

      {showAddressForm && (
        <AddressForm
          onSubmit={handleAddressSubmit}
          onClose={() => setShowAddressForm(false)}
        />
      )}
    </div>
  );
};

export default AddressCheckout;