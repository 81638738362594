import React, { useState, useEffect } from 'react';

const AnimatedBanner = () => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const messages = [
    "🔖 Extra 5% OFF on Prepaid Payment",
    "🚛 Free Shipping Above 399"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, 5000); // Change message every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const bannerStyle = {
    background: 'linear-gradient(to right, #006666, #6cd4d9)',
    color: 'white',
    padding: '12px',
    overflow: 'hidden',
    position: 'relative',
    height: '20px'
  };

  const messageStyle = (isActive) => ({
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '600',
    transition: 'all 0.5s ease-in-out',
    transform: isActive ? 'translateY(0)' : 'translateY(-100%)',
    opacity: isActive ? 1 : 0
  });

  return (
    <div style={bannerStyle}>
      {messages.map((message, index) => (
        <div
          key={index}
          style={messageStyle(index === currentMessage)}
        >
          {message}
        </div>
      ))}
    </div>
  );
};

export default AnimatedBanner;