// ProductPage.jsx
import React, { useState, useEffect } from 'react';
import Footer from './HomePageComponents/Footer';
import { useNavigate } from 'react-router-dom';
import FilterComponent from './ProductPageComponents/FilterComponent';
import ProductCard from './ProductPageComponents/ProductCard';
import '../styles/ProductPage.scss';
import instance from '../helpers/axiosconfig';
import CartSidebar from './CartPages/CartPage';
import { Filter, X } from 'lucide-react';

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} className={`star ${star <= rating ? 'filled' : ''}`}>
          ★
        </span>
      ))}
      <span className="rating-value">({rating.toFixed(1)})</span>
    </div>
  );
};

function ProductPage() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('name');
  const [filterCategory, setFilterCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState(2000);
  const [isCartOpen, setIsCartOpen] = useState(false);  
  const [cart, setCart] = useState([]);
  const [volumeRange, setVolumeRange] = useState(10);
  const [fragrance, setFragrance] = useState('All');
  const [qtyRange, setQtyRange] = useState(100);
  const [availability, setAvailability] = useState('All');
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileFilterOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get('/products');
      setProducts(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
    }
  };

  const filteredAndSortedProducts = products
    .filter(product => 
      (filterCategory === 'All' || product.category === filterCategory) &&
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      product.price <= priceRange &&
      (fragrance === 'All' || product.fragrance === fragrance) &&
      product.volume <= volumeRange &&
      product.quantity <= qtyRange &&
      (availability === 'All' || 
       (availability === 'In Stock' && product.stock > 0) || 
       (availability === 'Out of Stock' && product.stock === 0))
    )
    .sort((a, b) => {
      if (sortBy === 'name') return a.name.localeCompare(b.name);
      if (sortBy === 'price-asc') return a.price - b.price;
      if (sortBy === 'price-desc') return b.price - a.price;
      return 0;
    });

  useEffect(() => {
    const handleOpenCartSidebar = () => {
      setIsCartOpen(true);
    };

    window.addEventListener('openCartSidebar', handleOpenCartSidebar);
    return () => {
      window.removeEventListener('openCartSidebar', handleOpenCartSidebar);
    };
  }, []);

  const addToCart = (product) => {
    const updatedCart = [...cart];
    const existingItem = updatedCart.find(item => item._id === product._id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      updatedCart.push({ ...product, quantity: 1 });
    }
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));

    window.dispatchEvent(new CustomEvent('openCartSidebar'));
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const toggleMobileFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
    if (!isMobileFilterOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="product-page">
      <header className="header">
        <h1>All Products</h1>
        <button className="mobile-filter-toggle" onClick={toggleMobileFilter}>
          {isMobileFilterOpen ? <X size={24} /> : <Filter size={24} />}
        </button>
      </header>
      
      <main className="main-content">
        <div className={`filter-container ${isMobileFilterOpen ? 'mobile-open' : ''}`}>
          <div className="filter-header">
            <h2>Filters</h2>
            {isMobileFilterOpen && (
              <button className="close-filter" onClick={() => setIsMobileFilterOpen(false)}>
                <X size={24} />
              </button>
            )}
          </div>
          <FilterComponent
            filterCategory={filterCategory}
            setFilterCategory={setFilterCategory}
            volumeRange={volumeRange}
            setVolumeRange={setVolumeRange}
            fragrance={fragrance}
            setFragrance={setFragrance}
            qtyRange={qtyRange}
            setQtyRange={setQtyRange}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            availability={availability}
            setAvailability={setAvailability}
            onClose={() => setIsMobileFilterOpen(false)}
          />
        </div>
        
        <div className="product-area">
          <div className="search-sort">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="search-icon">🔍</span>
            </div>
            <div className="sort-dropdown">
              <span>Sort by:</span>
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="name">Name</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
              </select>
            </div>
          </div>
          
          <div className="product-grid">
            {filteredAndSortedProducts.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                onAddToCart={addToCart}
                onProductClick={handleProductClick}
              />
            ))}
          </div>
        </div>
      </main>
      
      <Footer />
      <CartSidebar 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cart={cart}
        setCart={setCart}
      />
      
      {isMobileFilterOpen && (
        <div className="mobile-overlay" onClick={toggleMobileFilter} />
      )}
    </div>
  );
}

export default ProductPage;