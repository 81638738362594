import React, { useState, useEffect } from 'react';
import '../../styles/ImageSlider.scss';

const ImageSlider = ({ images = [], interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (images.length === 0) return;
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);
    return () => clearInterval(timer);
  }, [images.length, interval]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  if (images.length === 0) {
    return (
      <div className="slider-wrapper">
        <div className="slider-container empty">
          <p>No Image Available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="slider-wrapper">
      <div className="slider-container">
        <div
          className="slider"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div className="slide" key={index}>
              <img 
                src={image} 
                alt={`Slide ${index + 1}`} 
                className="slide-img"
                loading="lazy" // Added for better performance
              />
            </div>
          ))}
        </div>
        {/* Added navigation dots */}
        <div className="slider-dots">
          {images.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;