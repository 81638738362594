import React, { useState, useEffect, useRef } from 'react';
import '../../styles/ProductShowcase.scss'; 

const FeatureIcon = ({ iconClass, text }) => (
  <div className="feature-icon">
    <div className={`icon-wrapper ${iconClass}`}></div>
    <span>{text}</span>
  </div>
);

const Bubble = ({ onPop, index }) => {
  const [popped, setPopped] = useState(false);
  const bubbleRef = useRef(null);

  useEffect(() => {
    const bubble = bubbleRef.current;
    const duration = 5000 + Math.random() * 5000; // Random duration between 5-10 seconds
    const delay = index * 1000; // Stagger the start of each bubble

    bubble.style.animationDuration = `${duration}ms`;
    bubble.style.animationDelay = `${delay}ms`;

    const timer = setTimeout(() => {
      setPopped(true);
      onPop();
    }, duration + delay);

    return () => clearTimeout(timer);
  }, [onPop, index]);

  return (
    <div 
      ref={bubbleRef}
      className={`bubble ${popped ? 'popped' : ''}`} 
      style={{
        left: `${Math.random() * 80 + 10}%`, // Random horizontal position
      }}
    ></div>
  );
};

const ProductShowcase = () => {
  const showcaseRef = useRef(null);
  const [bubbles, setBubbles] = useState([]);
  const [bubbleCount, setBubbleCount] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          showcaseRef.current.classList.add('animate-in');
        }
      },
      { threshold: 0.1 }
    );

    if (showcaseRef.current) {
      observer.observe(showcaseRef.current);
    }

    return () => {
      if (showcaseRef.current) {
        observer.unobserve(showcaseRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBubbles(prevBubbles => [...prevBubbles, bubbleCount]);
      setBubbleCount(prevCount => prevCount + 1);
    }, 1000); // Add a new bubble every second

    return () => clearInterval(interval);
  }, [bubbleCount]);

  const handleBubblePop = () => {
    // You can add sound effects or other actions here
    console.log('Pop!');
  };

  return (
    <div className="product-showcase" ref={showcaseRef}>
      <div className="content-wrapper">
        <div className="product-info">
          <h1 className="title">Malak Bathroom Cleaner</h1>
          <div className="category">
            <div className="icon home-icon"></div>
            <span>HOME CARE</span>
          </div>
          <h2 className="product-name">Thicker & Fresher</h2>
          <p className="description">
            10x Better Cleaning with our disinfectant formula. 
            Effectively removes tough stains and kills 99.9% of germs.
          </p>
          <div className="features">
            <FeatureIcon iconClass="droplet-icon" text="POWERFUL FORMULA" />
            <FeatureIcon iconClass="leaf-icon" text="FRESH SCENT" />
            <FeatureIcon iconClass="clock-icon" text="LONG LASTING CLEAN" />
          </div>
          <button className="shop-now">SHOP NOW</button>
        </div>
      </div>
      <div className="image-wrapper">
        <img src="/1bg.png" alt="Malak Bathroom Cleaner" className="product-image" />
        <div className="glow-effect"></div>
        <div className="bubbles">
          {bubbles.map((id) => (
            <Bubble key={id} onPop={handleBubblePop} index={id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;