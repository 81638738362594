import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../helpers/axiosconfig';

const CartSidebar = ({ isOpen, onClose, cart, setCart }) => {
  const navigate = useNavigate();
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [coupons] = useState([
    { id: 1, code: 'SAVE10', discount: 10, type: 'percentage' },
    { id: 2, code: 'FLAT50', discount: 50, type: 'fixed' },
    { id: 3, code: 'SUMMER20', discount: 20, type: 'percentage' },
  ]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('cart-open');
    } else {
      document.body.classList.remove('cart-open');
    }
    return () => {
      document.body.classList.remove('cart-open');
    };
  }, [isOpen]);

  const updateQuantity = (id, change) => {
    const updatedCart = cart.map(item => 
      item._id === id ? { ...item, quantity: Math.max(1, item.quantity + change) } : item
    );
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));
  };

  const removeFromCart = (id) => {
    const updatedCart = cart.filter(item => item._id !== id);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    
    window.dispatchEvent(new CustomEvent('cartUpdate', {
      detail: { cart: updatedCart }
    }));
  };

  const getSubtotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const getDiscountAmount = () => {
    if (!selectedCoupon) return 0;
    const subtotal = getSubtotal();
    return selectedCoupon.type === 'percentage' 
      ? subtotal * (selectedCoupon.discount / 100)
      : selectedCoupon.discount;
  };

  const getTotalPrice = () => {
    const subtotal = getSubtotal();
    const discount = getDiscountAmount();
    return (subtotal - discount).toFixed(2);
  };

  const applyCoupon = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const removeCoupon = () => {
    setSelectedCoupon(null);
  };

  const handlePaymentSuccess = async (response) => {
    try {
      // Verify the payment
      await axios.post('/order/verify', {
        razorpayOrderId: response.razorpay_order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpaySignature: response.razorpay_signature
      });

      // Clear cart and redirect to success page
      setCart([]);
      localStorage.removeItem('cart');
      navigate('/order-success');
    } catch (error) {
      console.error('Payment verification failed:', error);
      alert('Payment verification failed. Please contact support.');
    }
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handleCheckout = async () => {
    try {
      setIsProcessing(true);
      
      // Create order in your backend
      const orderData = {
        amount: parseFloat(getTotalPrice()),
        currency: 'INR',
        items: [], // Add your items here if applicable
        customer: {
          // Populate customer details if available
          name: '', // Provide a name if you have it
          email: '', // Provide an email if you have it
          mobile: '' // Provide a mobile number if you have it
        }
      };
      
      const response = await axios.post('/order/create', orderData);
      const { razorpayOrderId, razorpayConfig } = response.data;
  
      const res = await initializeRazorpay();
      if (!res) {
        alert('Razorpay SDK failed to load');
        return;
      }
  
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: razorpayConfig.amount, // Use the amount from the razorpayConfig
        currency: razorpayConfig.currency, // Use the currency from the razorpayConfig
        name: 'Your Company Name', // Update this with your company name
        description: 'Purchase Description',
        order_id: razorpayOrderId, // Use the order ID returned from your backend
        handler: handlePaymentSuccess,
        prefill: {
          name: orderData.customer.name, // Populate with customer name
          email: orderData.customer.email, // Populate with customer email
          contact: orderData.customer.mobile // Populate with customer mobile
        },
        theme: {
          color: '#3399cc'
        }
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Checkout failed:', error);
      alert('Failed to initialize checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };
  

  return (
    <>
      {isOpen && <div className="cart-sidebar-overlay" onClick={onClose}></div>}
      <div className={`cart-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="cart-sidebar-header">
          <h2>Your Cart</h2>
          <button onClick={onClose} className="close-btn">✕</button>
        </div>

        <div className="cart-sidebar-items">
          {cart.length === 0 ? (
            <div className="empty-cart">
              <span className="cart-icon">🛒</span>
              <p>Your cart is empty</p>
            </div>
          ) : (
            cart.map((item) => (
              <div key={item._id} className="cart-item">
                <img src={item.imageUrl && item.imageUrl[0]} alt={item.name} className="item-image" />
                <div className="item-details">
                  <h3>{item.name}</h3>
                  <p className="item-price">₹{item.price.toLocaleString('en-IN')}</p>
                  <div className="quantity-control">
                    <button onClick={() => updateQuantity(item._id, -1)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => updateQuantity(item._id, 1)}>+</button>
                  </div>
                </div>
                <button onClick={() => removeFromCart(item._id)} className="remove-btn">🗑️</button>
              </div>
            ))
          )}
        </div>

        <div className="cart-sidebar-coupons">
          <h3>Available Coupons</h3>
          {coupons.map((coupon) => (
            <button
              key={coupon.id}
              onClick={() => applyCoupon(coupon)}
              className={`coupon-btn ${selectedCoupon?.id === coupon.id ? 'active' : ''}`}
            >
              {coupon.code} - {coupon.type === 'percentage' ? `${coupon.discount}% off` : `₹${coupon.discount} off`}
            </button>
          ))}
          {selectedCoupon && (
            <button onClick={removeCoupon} className="remove-coupon-btn">
              Remove Coupon
            </button>
          )}
        </div>

        <div className="cart-sidebar-footer">
          <div className="subtotal">
            <span>Subtotal:</span>
            <span>₹{getSubtotal().toLocaleString('en-IN')}</span>
          </div>
          {selectedCoupon && (
            <div className="discount">
              <span>Discount:</span>
              <span>-₹{getDiscountAmount().toLocaleString('en-IN')}</span>
            </div>
          )}
          <div className="total">
            <span>Total:</span>
            <span>₹{parseFloat(getTotalPrice()).toLocaleString('en-IN')}</span>
          </div>
          <button
            className="checkout-btn"
            onClick={handleCheckout}
            disabled={cart.length === 0 || isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Proceed to Checkout'}
          </button>
        </div>
      </div>
    </>
  );
};

export default CartSidebar;