import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import CartSidebar from '../CartPages/CartPage';
import SignInModal from '../Signin_up/Signin';
import CustomIcon from '../../utils/CustomIcon';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/Navbar.scss';
import '../../styles/CartPage.scss';

// CartIcon Component
const CartIcon = ({ count, onClick }) => {
  const [animate, setAnimate] = useState(false);
  const [prevCount, setPrevCount] = useState(count);

  useEffect(() => {
    if (count !== prevCount) {
      setAnimate(true);
      const timer = setTimeout(() => setAnimate(false), 300);
      setPrevCount(count);
      return () => clearTimeout(timer);
    }
  }, [count, prevCount]);

  return (
    <button 
      onClick={onClick} 
      className="navbar__action-btn relative"
      aria-label="Shopping cart"
    >
      <CustomIcon name="shopping" className="navbar__icon navbar__cart-icon-svg" />
      {count > 0 && (
        <span className={`cart-count ${animate ? 'animate' : ''}`}>
          {count}
        </span>
      )}
    </button>
  );
};

// Desktop Navbar Component
const DesktopNavbar = ({ 
  showIntro, 
  cart, 
  toggleCart, 
  toggleSidebar,
  toggleMenu,
  isSidebarOpen,
  isMenuOpen,
  handleNavigation,
  openSignInModal
}) => {
  return (
    <nav className={`navbar ${showIntro ? 'hidden' : ''}`}>
      <div className="navbar__container">
        <button 
          className="navbar__sidebar-toggle" 
          onClick={toggleSidebar}
          aria-label="Toggle sidebar"
        >
          <div className="icon-container">
            {isSidebarOpen ? (
              <CloseIcon 
                className="rotating-enter"
                sx={{ 
                  fontSize: 24,
                  transition: 'all 0.2s ease-in-out',
                  color: '#54c5cb'
                }} 
              />
            ) : (
              <FormatListBulletedIcon 
                className="rotating-enter"
                sx={{ 
                  fontSize: 24,
                  transition: 'all 0.2s ease-in-out',
                  color: '#54c5cb'
                }} 
              />
            )}
          </div>
        </button>

        <div className="navbar__logo">
          <Link to="/">
            <img 
              src="/malak.png" 
              alt="Malak Logo" 
              className={`navbar__logo-img ${showIntro ? '' : 'animated-logo'}`} 
            />
          </Link>
        </div>

        <div className="navbar__links">
        <button 
  onClick={openSignInModal} 
  className="navbar__action-btn navbar__user-btn"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    padding: '8px',
    color: '#043c3f', // Using your $primary-color
    transition: 'all 0.3s ease' // Using your $transition-duration
  }}
>
  <CustomIcon 
    name="user" 
    className="navbar__icon navbar__user-icon-svg"
  />
</button>

          <Link to="/" className="navbar__link">Home</Link>
          <Link to="/products" className="navbar__link">Products</Link>
          <Link to="/about" className="navbar__link">About</Link>
          <Link to="/contact" className="navbar__link">Contact</Link>
        </div>

        <div className="navbar__actions">
          <button className="navbar__action-btn" aria-label="Search">
            <CustomIcon name="search" />
          </button>
          
          <button 
            onClick={openSignInModal} 
            className="navbar__action-btn" 
            aria-label="User account"
          >
            <CustomIcon name="user" />
          </button>

          <CartIcon count={cart.length} onClick={toggleCart} />

          <button 
            className="navbar__menu-btn" 
            onClick={toggleMenu} 
            aria-label="Toggle menu"
          >
            <CustomIcon name="menu" />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`navbar__mobile-menu ${isMenuOpen ? 'is-open' : ''}`}>
        <div className="navbar__mobile-menu-overlay" onClick={toggleMenu}></div>
        <div className="navbar__mobile-menu-content">
          <button 
            className="navbar__mobile-menu-close" 
            onClick={toggleMenu} 
            aria-label="Close menu"
          >
            <CustomIcon name="close" />
          </button>

          <div className="navbar__mobile-menu-links">
            <button 
              onClick={openSignInModal} 
              className="navbar__mobile-menu-link"
            >
              <CustomIcon 
                name="user" 
                className="navbar__mobile-menu-icon navbar__user-icon-svg" 
              />
              Sign In
            </button>

            <button 
              onClick={toggleCart} 
              className="navbar__mobile-menu-link relative"
            >
              <CustomIcon 
                name="shopping" 
                className="navbar__mobile-menu-icon navbar__cart-icon-svg" 
              />
              Cart
              {cart.length > 0 && (
                <span className="cart-count">
                  {cart.length}
                </span>
              )}
            </button>

            <button 
              onClick={() => handleNavigation('/')} 
              className="navbar__mobile-menu-link"
            >
              Home
            </button>
            <button 
              onClick={() => handleNavigation('/products')} 
              className="navbar__mobile-menu-link"
            >
              Products
            </button>
            <button 
              onClick={() => handleNavigation('/about')} 
              className="navbar__mobile-menu-link"
            >
              About
            </button>
            <button 
              onClick={() => handleNavigation('/contact')} 
              className="navbar__mobile-menu-link"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

// Mobile Navbar Component
const MobileNavbar = ({ cart, toggleCart, toggleSidebar, isSidebarOpen, openSignInModal }) => {
  return (
    <nav className="navbar navbar--mobile">
      <div className="navbar__container">
        <div className="navbar__left">
          <button 
            className="navbar__sidebar-toggle" 
            onClick={toggleSidebar}
            aria-label="Toggle sidebar"
          >
            <div className="icon-container">
              {isSidebarOpen ? (
                <CloseIcon className="rotating-enter" />
              ) : (
                <FormatListBulletedIcon className="rotating-enter" />
              )}
            </div>
          </button>
        </div>

        <div className="navbar__logo navbar__logo--mobile">
          <Link to="/">
            <img 
              src="/malak.png" 
              alt="Malak Logo" 
              className="navbar__logo-img" 
            />
          </Link>
        </div>

        <div className="navbar__actions navbar__actions--mobile">
          <button 
            onClick={openSignInModal}
            className="navbar__action-btn" 
            aria-label="User account"
          >
            <CustomIcon name="user" className="navbar__icon navbar__user-icon-svg" />
          </button>
          
          <button 
            className="navbar__action-btn" 
            aria-label="Search"
          >
            <CustomIcon name="search" className="navbar__icon navbar__search-icon-svg" />
          </button>
          
          <CartIcon count={cart.length} onClick={toggleCart} />
        </div>
      </div>
    </nav>
  );
};

// Main ResponsiveNavbar Component
const ResponsiveNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const navigate = useNavigate();

  // Check for mobile view
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Initial setup and intro animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 3000);

    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);

    return () => clearTimeout(timer);
  }, []);

  // Cart and storage event listeners
  useEffect(() => {
    const handleCartUpdate = (event) => {
      setCart(event.detail.cart);
    };

    const handleStorageChange = () => {
      const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
      setCart(storedCart);
    };

    const handleOpenCartSidebar = () => {
      setIsCartOpen(true);
    };

    window.addEventListener('cartUpdate', handleCartUpdate);
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('openCartSidebar', handleOpenCartSidebar);
    
    return () => {
      window.removeEventListener('cartUpdate', handleCartUpdate);
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('openCartSidebar', handleOpenCartSidebar);
    };
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleCart = () => setIsCartOpen(!isCartOpen);
  
  const openSignInModal = () => {
    setIsSignInOpen(true);
    setIsMenuOpen(false); // Close mobile menu if it's open
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <>
      {showIntro && (
        <div className="intro-overlay">
          <img src="/malak.png" alt="Malak Logo" className="intro-logo" />
        </div>
      )}

      {isMobile ? (
        <MobileNavbar 
          cart={cart}
          toggleCart={toggleCart}
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          openSignInModal={openSignInModal}
        />
      ) : (
        <DesktopNavbar 
          showIntro={showIntro}
          cart={cart}
          toggleCart={toggleCart}
          toggleSidebar={toggleSidebar}
          toggleMenu={toggleMenu}
          isSidebarOpen={isSidebarOpen}
          isMenuOpen={isMenuOpen}
          handleNavigation={handleNavigation}
          openSignInModal={openSignInModal}
        />
      )}

      {/* Sidebars and Modals */}
      <Sidebar 
        isOpen={isSidebarOpen} 
        onClose={() => setIsSidebarOpen(false)} 
      />
      <CartSidebar 
        isOpen={isCartOpen}
        onClose={() => setIsCartOpen(false)}
        cart={cart}
        setCart={setCart}
      />
      <SignInModal
        isOpen={isSignInOpen}
        onClose={() => setIsSignInOpen(false)}
      />
    </>
  );
};

export default ResponsiveNavbar;