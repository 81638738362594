import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import instance from '../../helpers/axiosconfig';

const StarRating = ({ rating = 5 }) => (
  <div className="star-rating">
    {[1, 2, 3, 4, 5].map((star) => (
      <span key={star} className={star <= rating ? 'star filled' : 'star'}>
        ★
      </span>
    ))}
  </div>
);

const ProductCard = ({ id, imageUrl, name, price, discount, volume, rating }) => {
  const navigate = useNavigate();
  const originalPrice = price;
  const discountedPrice = price - (price * (discount / 100));
  
  const handleQuickView = () => {
    navigate(`/product/${id}`);
  };
  
  return (
    <div className="product-card">
      <div className="image-container">
        <img 
          src={Array.isArray(imageUrl) ? imageUrl[0] : imageUrl} 
          alt={name} 
          className="product-image" 
        />
        <div className="overlay">
          <button className="quick-view-btn" onClick={handleQuickView}>Quick View</button>
        </div>
      </div>
      <h3 className="product-name">{name}</h3>
      <p className="product-volume">{volume} Ltr</p>
      <StarRating rating={rating} />
      <div className="product-price">
        <span className="current-price">₹{discountedPrice.toFixed(2)}</span>
        <span className="original-price">MRP: ₹{originalPrice.toFixed(2)}</span>
      </div>
      <button className="choose-options-btn" onClick={handleQuickView}>CHOOSE OPTIONS +</button>
    </div>
  );
};

const ChevronLeft = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

const ChevronRight = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

const BestSellers = () => {
  const [activeTab, setActiveTab] = useState('Laundry Detergent');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const scrollContainerRef = useRef(null);

  const categories = ['Laundry Detergent', 'Floor Cleaner', 'Dish Wash'];

  useEffect(() => {
    fetchProducts();
  }, [activeTab]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await instance.get(`/products?category=${activeTab}`);
      const bestSellers = response.data.slice(0, 5);
      setProducts(bestSellers);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products. Please try again later.');
      setLoading(false);
    }
  };

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -container.offsetWidth : container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="best-sellers">
      <h2 className="section-title">Best Sellers</h2>
      <div className="category-tabs">
        {categories.map((tab) => (
          <button
            key={tab}
            className={`category-tab ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="products-container">
        <button 
          className="nav-btn prev" 
          onClick={() => scroll('left')}
          disabled={products.length <= 4}
        >
          <ChevronLeft />
        </button>
        <div className="products-scroll" ref={scrollContainerRef}>
          {products.length > 0 ? (
            products.map((product) => (
              <ProductCard
                key={product._id}
                id={product._id}
                imageUrl={product.imageUrl}
                name={product.name}
                price={product.price}
                discount={product.discount}
                volume={product.volume}
                rating={5}
              />
            ))
          ) : (
            <div>No products found in this category.</div>
          )}
        </div>
        <button 
          className="nav-btn next" 
          onClick={() => scroll('right')}
          disabled={products.length <= 4}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default BestSellers;