import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/orders.scss';

const OrdersList = () => {
  const navigate = useNavigate();
  const [orders] = useState([
    {
      id: '1',
      customerName: 'John Doe',
      orderDate: '2024-10-26',
      status: 'pending',
      total: 9999,
      items: [
        { name: 'Blue T-Shirt', quantity: 2, price: 2999 },
        { name: 'Black Jeans', quantity: 1, price: 4001 }
      ]
    },
    {
      id: '2',
      customerName: 'Jane Smith',
      orderDate: '2024-10-26',
      status: 'delivered',
      total: 15999,
      items: [
        { name: 'Running Shoes', quantity: 1, price: 15999 }
      ]
    }
  ]);

  const [filterStatus, setFilterStatus] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const handleTrackStatus = (orderId) => {
    navigate(`tracking/${orderId}`); // Changed from /order-tracking/${orderId}
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0,
    }).format(price);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const getStatusIcon = (status) => {
    const icons = {
      pending: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M12 6v6l4 2" />
        </svg>
      ),
      delivered: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M8 12l3 3 6-6" />
        </svg>
      ),
      processing: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M12 6v2M12 16v2M6 12h2M16 12h2" />
        </svg>
      ),
      cancelled: (
        <svg className="status-icon" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="10" className="status-circle" />
          <path d="M15 9l-6 6M9 9l6 6" />
        </svg>
      )
    };
    return icons[status] || icons.pending;
  };

  return (
    <div className="orders-dashboard">
      <header className="dashboard-header">
        <div className="header-content">
          <div className="header-title">
            <div className="header-icon">
              <svg viewBox="0 0 24 24">
                <path d="M21 8l-3-3H6L3 8m18 0v12H3V8m18 0H3m9 4h.01" />
              </svg>
            </div>
            <h1>Customer Orders</h1>
          </div>

          <div className="header-controls">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search orders..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <svg viewBox="0 0 24 24">
                  <circle cx="11" cy="11" r="8" />
                  <path d="M21 21l-4.35-4.35" />
                </svg>
              </span>
            </div>

            <select
              className="filter-select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Orders</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
        </div>
      </header>

      <div className="orders-grid">
        {orders.map((order) => (
          <div key={order.id} className="order-card">
            <div className="order-header">
              <div className="customer-details">
                <div className="customer-avatar">
                  {order.customerName.charAt(0)}
                </div>
                <div className="customer-info">
                  <h3>{order.customerName}</h3>
                  <span className="order-date">
                    <svg className="calendar-icon" viewBox="0 0 24 24">
                      <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
                      <line x1="16" y1="2" x2="16" y2="6" />
                      <line x1="8" y1="2" x2="8" y2="6" />
                      <line x1="3" y1="10" x2="21" y2="10" />
                    </svg>
                    {formatDate(order.orderDate)}
                  </span>
                </div>
              </div>

              <div className="order-meta">
                <div className={`status-badge ${order.status}`}>
                  {getStatusIcon(order.status)}
                  <span>{order.status}</span>
                </div>
                <div className="order-total">{formatPrice(order.total)}</div>
              </div>
            </div>

            <div className="order-items">
              {order.items.map((item, index) => (
                <div key={index} className="item-row">
                  <div className="item-info">
                    <span className="item-name">{item.name}</span>
                    <span className="item-quantity">×{item.quantity}</span>
                  </div>
                  <span className="item-price">
                    {formatPrice(item.price * item.quantity)}
                  </span>
                </div>
              ))}
            </div>

            <div className="order-actions">
              <button 
                className="track-status-btn"
                onClick={() => handleTrackStatus(order.id)}
              >
                Track Status
                <svg className="arrow-icon" viewBox="0 0 24 24">
                  <path d="M9 18l6-6-6-6" />
                </svg>
              </button>
              
              <div className="secondary-actions">
                <button className="download-btn">
                  <svg viewBox="0 0 24 24">
                    <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" />
                  </svg>
                  Invoice
                </button>
                <button className="details-btn">View Details</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrdersList;